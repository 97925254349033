<template>
  <section class="checkout page-content">
    <bike_head_mobile v-if="router.currentRoute.value.name == 'BikeSecureCheckout'"/>
    <head_mobile v-else/>
    <div class="container" v-if="checkout">
      <div class="checkout-container main-container">
        <div class="checkout_row">
          <div class="page-title plain">
            <h2 class="mb-0"> {{ $t('secure_checkout') }} </h2>
            <p>
              {{ $t('qoute_reference') }} <strong>{{ checkout.merchant_reference }}</strong>
            </p>
          </div>
          <div class="checkout-block checkout-block-page">
            <!-- first part -->
            <div class="checkout-top">
              <div class="row g-0">
                <div class="col-lg-10 col-md-12 left-col">
                  <div class="checkout-top__block">
                    <div
                      class="result-listing__item__block_top_row d-flex flex-wrap align-items-center"
                    >
                      <div class="partner-logo">
                        <img
                          :src="`${domain}${url}/img/${policy.image}`"
                          width="180"
                          class="img-fluid"
                        />
                      </div>

                      <div class="partner-content">
                        <div class="partner-content_title d-flex">
                          <h5 class="mb-0">
                            {{ policy.policy_name }}
                          </h5>
                          <div class="ms-4 partner-content_bottom_item-container">
                            <div class="partner-content_bottom_item" v-if="isBike">
                              <div class="icon">
                                <img
                                  src="@/assets/images/icons/bike-insurance.png"
                                  class="img-fluid"
                                />
                              </div>
                              <div class="title">
                                <div class="text" >
                                  {{ checkout.manufacturer }} {{ checkout.trim_level }}
                                </div>
                              </div>
                            </div>
                            <div class="partner-content_bottom_item" v-else>
                              <div class="icon">
                                <img
                                  src="@/assets/images/icons/car-protection.png"
                                  class="img-fluid"
                                />
                              </div>
                              <div class="title">
                                <div class="text">
                                  {{ checkout.manufacturer }}
                                  {{ checkout.model }} {{ checkout.trim_level }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="partner-content_bottom d-flex">
                          <div class="partner-content_bottom d-flex">
                      <div class="partner-content_bottom_item cross-icon" v-if="policy.policy_type == 5">
                        <p>
                          {{ $t("own_damage") }}
                          
                          <div class="listing-ul">
                            <ul>
                              <li class="cross"
                              > </li>
                            </ul>
                          </div>
                        </p>
                      </div>
                      <div class="partner-content_bottom_item" v-else>
                        <p>
                          {{ $t("own_damage") }}
                          <span>
                            <span>{{ policy.own_damage_cover.value}}</span>
                          </span>
                        </p>
                      </div>
                      <div class="partner-content_bottom_item">
                        <p>
                          {{ $t("third_party_limit") }}
                          <span>{{ policy.third_party_limit.value}}</span>
                          
                        </p>
                      </div>
                      <div class="partner-content_bottom_item">
                        <p>
                          {{ $t("excess_amount") }}

                          <span v-if="policy.excess_amount">
                            {{ $filters.currency(policy.excess_amount.value) }}
                          </span>
                          <span v-else> {{ $filters.currency(0) }} </span>
                        </p>
                      </div>
                    </div>

                          <!-- <div class="partner-content_bottom_item" v-if="!isBike">
                            <div class="icon">
                              <img
                                src="@/assets/images/icons/calender.png"
                                class="img-fluid"
                              />
                            </div>
                          
                            <div class="title" >
                              <div class="text">
                                {{ dateConvert(dd.policy_start_date) }}
                                {{ $t("to") }}
                                {{
                                  dateConvert(
                                    datePlus(dd.policy_start_date)
                                  )
                                }}
                              </div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-12 right-col">
                  <div class="right-buy-now text-center h-100">
                    <div class="right-buy-now-top">
                      <div class="premium-title">
                        <p> {{ $t('total_premium') }} </p>
                      </div>
                      <div class="price-title">
                        <h4 class="mb-0">
                          {{ $filters.currency(policy.policy_price) }}
                        </h4>
                      </div>
                      <div class="partner-content_bottom_item">
                       
                          <a v-if="isBike && policy.bike_terms_url" :href="`${domain}${bikePlanUrl}/tc/${policy.bike_terms_url}`"
                              target="_blank">{{ $t('read_terms') }}<span class="icon-chevron-right"></span></a>
                          <a v-else-if="!isBike && policy.terms_url" :href="`${domain}${url}/tc/${policy.terms_url}`"
                              target="_blank">{{ $t('read_terms') }}<span class="icon-chevron-right"></span></a>
                          <a v-else href="javascript::void(0)" style="cursor: default;">{{ $t('read_terms') }}<span class="icon-chevron-right"></span></a>
                       
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <!-- second part -->
            
            <div class="checkout-middle" >
              <div class="result-listing__item__block_medium">
                <div class="row gx-5">
                  <div class="col-md-3">
                    <div class="listing-ul">
                      <ul>
                       
                        <li
                          :class="!policy.agency_repair || (policy.agency_repair && policy.agency_repair.applicable === 0) ? 'cross' : 'check'"
                        >
                          {{ $t("agency_repair") }}
                        </li>
                        <li
                          :class="!policy.roadside_assistance || (policy.roadside_assistance && policy.roadside_assistance.applicable === 0) ? 'cross' : 'check'"
                        >
                          {{ $t("roadside_assistance") }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- checkbox for personal accident -->
                  <div class="col-md-5">
                    <div class="listing-ul">
                      <ul>
                        <li v-if="policy.personal_accident_driver > 0" class="p-0">
                          <div class="custom-checkbox">
                            
                              <input
                                class="styled-checkbox"
                                type="checkbox"
                                v-model="policy.value_personal_driver"
                                :id="`value_personal_driver_${numeric}`"
                                @change="
                                  addValueDriver(policy)
                                "
                              />

                              <label :for="`value_personal_driver_${numeric}`">
                              {{ $t("personal_accident_driver") }} QAR {{ policy.personal_accident_driver }} 
                            </label>
                          </div>
                        </li>
                        <li v-if="policy.personal_accident_passenger > 0">
                            <div class="custom-checkbox">
                              <input
                                  class="styled-checkbox"
                                  type="checkbox"
                                  v-model="policy.value_personal_passenger"
                                  :id="`value_personal_passenger_${numeric}`"
                                  @change="
                                    addValuePassenger(policy)
                                  "
                                />
                              <label :for="`value_personal_passenger_${numeric}`">
                                {{ $t("personal_accident_passenger") }} QAR {{ policy.personal_accident_passenger }}
                              </label>
                            </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                    <!-- extra add ons -->
                    <div class="col-lg-4 col-md-6 extra-add-col" v-if="policy.addons && policy.addons.length > 0">
                      <div class="listing-title">
                        <h6 class="">{{ $t("extra_addon") }}</h6>
                      </div>
                      <div
                        class="extra-addons d-flex align-items-center"
                        v-for="(addon, index) in policy.addons"
                        :key="index"
                      >
                        <div class="addons-checkbox">
                          <input
                            class="styled-checkbox"
                            id="replacement"
                            type="checkbox"
                            v-model="addon.addonCheck"
                            @change="addonAdd(addon, policy)"
                          />
                          <label> </label>
                        </div>
                        <div class="addons-text">
                          <div>
                            {{ language === 'ar' ? addon.add_on_ar : addon.add_on }}
                            <span>
                              <strong v-if="addon.addonValue">
                                {{ $filters.currency(addon.addonValue.price) }}
                              </strong>
                              
                              <el-select
                                @change="changeAddon(addon, policy)"
                                v-model="addon.addonValue"
                                filterable
                                value-key="details"
                                class="select-input addon-select"
                              >
                                <el-option
                                  class="addon-option"
                                  v-for="(item, index) in addon.addon_attr"
                                  :key="index"
                                  :label="language === 'ar' ? item.details_ar : item.details"
                                  :value="item"
                                />
                              </el-select>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              
            </div>
            <div class="checkout-middle-footer">
              <div class="result-listing__item__block">
                <div
                  class="result-listing__item__block-bottom"
                  :class="readMore ? 'open' : ''"
                  :style="`display: ${
                    readMore ? 'block' : 'none'
                  }`"
                >
                  <div class="result-listing__item__block-bottom-top">
                    <div class="row justify-content-lg-between">
                      <div class="col-lg-3 col-md-4 ">
                        <div class="listing-title">
                          <h6 class="mb-0"> {{ $t('car_coverage') }} </h6>
                        </div>
                        <div class="listing-ul">
                          <ul>
                            <li
                              v-for="(
                                coverage, index
                              ) in policy.benefit_car_coverage"
                              :key="index"
                              :class="
                                coverage.applicable ? 'check' : 'cross'
                              "
                            >
                              {{ coverage.title }}
                              <span v-if="coverage.value">{{ coverage.value }} </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-4 road-assistant">
                        <div class="listing-title">
                          <h6 class="mb-0"> {{ $t('extra_coverage') }} </h6>
                        </div>
                        <div class="listing-ul">
                          <ul>
                            <li
                              v-for="(
                                coverage, index
                              ) in policy.benefit_extra_coverage"
                              :key="index"
                              :class="coverage.applicable ? 'check' : 'cross'"
                            >
                              {{ coverage.title }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-4 road-assistant">
                        <div class="listing-title">
                          <h6 class="mb-0"> {{ $t('special_feature') }} </h6>
                        </div>
                        <div class="listing-ul">
                          <ul>
                            <li
                              v-for="(
                                coverage, index
                              ) in policy.benefit_special_features"
                              :key="index"
                              :class="coverage.applicable ? 'check' : 'cross'"
                            >
                              {{ coverage.title }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="checkout-block-details checkout-bloc-custom"
              v-if="policy.type == 'Comprehensive' ? true : false"
            >
              <button
                class="btn"
                :class="readMore ? 'active' : ''"
                @click="readMore = !readMore"
              >
                <i>{{
                  !readMore
                    ? $t('show_full_summary')
                    : $t('hide_full_summary')
                }}</i>
                <span class="icon-dropdown-down"></span>
              </button>
            </div>

            <div class="checkout-bottom checkout-bottom-container">
              <div class="checkout-bottom_block">
                <div class="row">
                  <div class="col-md-8 left-col">
                    <div class="checkout-left-row-block">
                      <div class="checkout-left-row">
                        <div class="coupon-block-container">
                          <div class="coupon-block">
                            <h3> {{ $t('coupons') }} </h3>
                            <div class="coupon-block-form">
                              <input
                                type="text"
                                :placeholder="$t('coupon_ph')"
                                class="inputfield"
                                v-model="coupon"
                                @input="trimSpaces"
                              />
                              <input
                                type="submit"
                                v-if="!couponSuccess"
                                @click.prevent="couponCode"
                                :value="$t('apply')"
                                class="btn-outlined-blue no-border"
                              />
                              <input
                                type="submit"
                                v-else
                                @click.prevent="couponRemove"
                                :value="$t('remove')"
                                class="btn-outlined-red no-border ash-text-color"
                              />
                            </div>
                            <span class="error" v-if="couponError">
                              <!-- {{ language === 'ar' ? couponError.ar  : couponError.en}} -->
                              {{ couponError}}
                            </span>
                            <span class="success" v-show="couponSuccess">
                              {{ $t('coupon_applied') }}
                            </span>
                          </div>
                        </div>
                        <div class="disclaimer-block">
                          <h3> {{ $t('disclaimer') }} </h3>
                          <p>
                            {{ $t('disclaimer_details') }}
                          </p>
                        </div>
                      </div>
                      
                    </div>
                  </div>

                  <div class="col-md-4 right-col">
                    <div class="summary">
                      <div class="inner-title">
                        <h5 class="mb-0"> {{ $t('summary') }} </h5>
                      </div>

                      <div class="summary-block">
                        <div class="summary-block__table">
                          <div class="summary-block__table--item">
                            <div class="left"> {{ $t('premium') }} </div>
                            <div class="right">
                              {{ $filters.currency(policy.sale_price, 2) }}
                            </div>
                          </div>
                          <div class="summary-block__table--item" v-if="policy.value_personal_driver">
                            <div class="left"> {{ $t('personal_accident_driver') }} </div>
                            <div class="right">
                              {{ $filters.currency(policy.personal_accident_driver, 2) }}
                            </div>
                          </div>
                          <div class="summary-block__table--item" v-if="policy.value_personal_passenger">
                            <div class="left"> {{ $t('personal_accident_passenger') }} </div>
                            <div class="right">
                              {{ $filters.currency(policy.personal_accident_passengers_amt, 2) }}
                            </div>
                          </div>
                          <template  v-if="policyAddOns && policyAddOns.length > 0">
                            <div class="summary-block__table--item" v-for="(addon, index) in policyAddOns" :key="index">
                              <div class="left"> {{ language === 'ar' ? addon.add_on_ar : addon.add_on }} <span style="font-weight: bold"> ( {{ language === 'ar' ? addon.addonValue.details_ar : addon.addonValue.details }} ) </span> </div>
                              <div class="right">
                                {{ $filters.currency(addon.addonValue.price, 2) }}
                              </div>
                            </div>
                          </template>
                          
                          <div
                            class="summary-block__table--item"
                            v-if="discount > 0"
                          >
                            <div class="left"> {{ $t('discount') }} </div>
                            <div class="right">
                              {{ $filters.currency(discount || 0, 2) }}
                            </div>
                          </div>
                          <div class="summary-block__table--item">
                            <div class="left"> {{ $t('vat_t') }} </div>
                            <div class="right">
                              {{ $filters.currency(vat || 0, 2) }}
                            </div>
                          </div>
                          <div class="summary-block__table--item">
                            <div class="left"> {{ $t('payable_total') }} </div>
                            <div class="right big-bold">
                              {{ $filters.currency(totlaPremium, 2) }}
                            </div>
                          </div>
                        </div>
                        <div class="agree-terms-conditions">
                          <div class="agree-terms-conditions-checkbox">
                            <input
                              class="styled-checkbox"
                              id="add-to-compare1"
                              type="checkbox"
                              value="value1"
                              v-model="isAccept"
                            />
                            <label for="add-to-compare1"
                              ><span
                                > {{ $t('agree') }}
                                <a v-if="isBike && policy.bike_terms_url" :href="`${domain}${bikePlanUrl}/tc/${policy.bike_terms_url}`"
                                  target="_blank">{{ $t('tac') }}</a>
                                <a v-else-if="!isBike && policy.terms_url" :href="`${domain}${url}/tc/${policy.terms_url}`"
                                  target="_blank">{{ $t('tac') }}</a>
                                <a v-else href="javascript::void(0)" style="cursor: default;">{{ $t('tac') }}</a>
                                  </span
                              ></label
                            >
                          </div>
                        </div>

                        <div class="checkout-button">
                          <button
                            type="button"
                            class="btn btn-checkout"
                            @click.prevent="checkoutHandler"
                            :disabled="!isAccept || isClick"
                          >
                            <span class="text-center" v-if="!isClick">
                                {{ $t('proceed') }}
                            </span>
                            <span class="text-center" v-else>
                                {{ $t('wait') }}
                                <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                ></span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, computed, ref } from "vue";
import { store } from "@/store";
import SecureCheckoutPage from "@/core/services/car/BuyNow";
import CarDriver from "@/core/services/car/Drivers";
import Stepper from "@/core/services/etc/Steppers";
import Coupons from "@/core/services/car/Coupons";
import Checkouts from "@/core/services/car/Checkouts";
import { useReCaptcha } from "vue-recaptcha-v3";
import head_mobile from '@/layouts/head_mobile.vue';
import bike_head_mobile from '@/layouts/bike_head_mobile.vue';
import router from '@/router';

    const isClick = ref(false);
    const isBike = ref(false);
    const first_registration_date = ref();

    onMounted(() => {
      isClick.value = false
      const routeValue = {
        vehicle: "done",
        driver: "done",
        compare: "done",
        cover: "active",
      };
      Stepper.setVehicleDetails(routeValue);
      SecureCheckoutPage.setCheckout(
        JSON.parse(window.localStorage.getItem("qoutes"))
      );
      SecureCheckoutPage.setBuyNow(
        JSON.parse(window.localStorage.getItem("buyNow"))
      );
      CarDriver.setInitialDriverDetails();


      const myStorage = window.localStorage.getItem("customer_detatils");
      console.log('myStorage',myStorage)
      if (myStorage) {
        const bike = JSON.parse(myStorage);
        //console.log('myStorage',bike)
        if(bike.bike_year !== undefined && bike.bike_year != null && bike.bike_year != 0)
        {
          isBike.value = true;
          first_registration_date.value = bike.bike_year+"-06-02";
        }

      }
    });

    const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
    const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);
    const bikePlanUrl = ref(process.env.VUE_APP_INSURANCE_BIKE_PLAN_URL);
    const coupon = ref();
    const captcha = useReCaptcha();

    const value_read_more = ref(false);

    // computed
    const checkout = computed(() => {
      return store.state.buyNow.checkout;
    });

    const policy = computed(() => {
      return store.state.buyNow.policy;
    });

    const isAccept = ref(true);

    const vat = computed(() => {
      return (
        ((policy.value.policy_price - discount.value) *
          (policy.value.vat ? policy.value.vat : 5)) /
        100
      );
    });

    const discount = computed(() => {
      let disc = 0;
      let coupon = store.state.coupon.success;
      if (coupon) {
        disc = coupon.discount_amount;
          // coupon.code_value_type == 1
          //   ? policy.value.policy_price * (coupon.code_value / 100)
          //   : coupon.code_value;
      }
      return disc;
    });

    const totalAmount = computed(() => {
      const sumofExtraAddons = policyAddOns.value.reduce((total, item) => {
        return total += item.addonValue.price;
      }, 0);

      const personal_passenger = policy.value.value_personal_passenger ? policy.value.personal_accident_passengers_amt : 0;
      const personal_driver = policy.value.value_personal_driver ? policy.value.personal_accident_driver : 0;
      return (policy.value.sale_price + sumofExtraAddons + personal_passenger + personal_driver) - discount.value 
    })

    const totlaPremium = computed(() => {
      
      return totalAmount.value + vat.value;
      // return (policy.value.policy_price - discount.value) + vat.value;
    });

    const dd = computed(() => {
      return store.state.drivers.driver;
    });

    const couponError = computed(() => {
      return store.state.coupon.error;
    });

    const couponSuccess = computed(() => {
      return store.state.coupon.success;
    });

    // function
    const dateConvert = (val) => {
      const arr = new Date(val).toString().split(" ");
      return arr[1] + " " + arr[2] + ", " + arr[3];
    };

    const datePlus = (val) => {
      const date = new Date(val);
      return date.setMonth(date.getMonth() + 13);
    };

    // click event
    const addValueDriver = (val) => {
      if (val.value_personal_driver) {
        val.policy_price += val.personal_accident_driver;
      } else {
        val.policy_price -= val.personal_accident_driver;
      }
    };

    const addValuePassenger = (val) => {
      if (val.value_personal_passenger) {
        val.policy_price += val.personal_accident_passenger;
      } else {
        val.policy_price -= val.personal_accident_passenger;
      }
    };

    const addonAdd = (val, original) => {
      if (val.addonCheck) {
        original.policy_price += val.addonValue.price;
        val.prev_price = val.addonValue.price;
      } else {
        original.policy_price -= val.prev_price;
      }
    };

    // change event
    const changeAddon = (val, original) => {
      if(val.addonCheck) {
        original.policy_price += val.addonValue.price;
        original.policy_price -= val.prev_price;
        val.prev_price = val.addonValue.price;
      }
      
    };

    // submit event
    const couponCode = () => {
        Coupons.postCoupon({
          promo_code: coupon.value,
          merchant_reference: checkout.value.merchant_reference,
          language:language.value,
          insurance_type:'car'
        });
    };

    const couponRemove = () => {
      coupon.value = '';
      Coupons.removeCoupon();
    };

    

    const checkoutHandler = async () => {
      isClick.value = true
      if(!couponSuccess.value) coupon.value = ''
      // load captcha
      await captcha?.recaptchaLoaded();
      // execute reCaptcha
      const token = await captcha?.executeRecaptcha("login");
      if (token) {
        const result = await Checkouts.postCheckout({
          uuid: window.localStorage.getItem("fall_back_dd"),
          policy: policy.value,
          quote: checkout.value,
          coupon: coupon.value,
          language: language.value,
          url: process.env.VUE_APP_PAYMENT_GATEWAY_URL,
        });
        store.state.vehicles.counter = 0;
        isClick.value = false
      } else {
        isClick.value = false
      }
    };

    const buyNow = computed(() => {
      return store.state.buyNow.buyNow;
    });

    const current = computed(() => {
      return store.state.steppers.stepper;
    });

    const readMore = ref(false);

    const language = computed(() => {
            return window.localStorage.getItem('language');
        })

    const policyAddOns = computed(() => {
      if(policy.value.addons) {
        return policy.value.addons.filter((val) => {
          if(val.addonCheck) {
            return { ...val };
          } 
        });
      }

      return [];

    })

    const trimSpaces = ()=>{
      coupon.value = coupon.value.replace(/\s+/g, '');
    }

</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .result-listing__item .price-tag-top {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: bottom left;
    top: 115px;
    left: 30px;
  }

  .result-listing__item__block-bottom.open {
    display: block;
    height: 100%;
    // opacity: 1;
  }
}

@media (max-width: 600px) {
  .result-listing__item__block-bottom.open {
    display: block;
    height: 100%;
    // opacity: 1;
  }
}

.result-listing__item .price-tag-top {
  background: #0061ff;
  color: #fff;
  font-size: 15.5px;
  padding: 15px 18px 15px;
  display: inline-flex;
  text-transform: uppercase;
  font-weight: bold;
}

.result-listing__item__block-bottom {
  display: block;
  height: 100%;
  //   opacity: 0;
  transition: height 0.4s;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
}

.error {
  color: red;
}

.success {
  color: blue;
}
</style>
