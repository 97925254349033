import { store } from "@/store";
import { StepperMutationTypes } from "@/core/enums/Stepper/Mutations";
import { StepperActionTypes } from "@/core/enums/Stepper/Actions";
import { Stepper } from '@/core/types/Steppers';

class Steppers{

    public static setVehicleDetails(payload: Stepper) {
        // console.log(payload)
        store
        .dispatch(StepperActionTypes.GET_STEP_VEHICLE_DETAILS, payload)
    }

    public static setDriver() {
        store.commit(StepperMutationTypes.SET_STEP_DRIVER_DETAILS);
    }

    public static setQoutes() {
        store.commit(StepperMutationTypes.SET_STEP_COMPARE_QOUTES);
    }

    // public static setCover() {
    //     store.commit(StepperMutationTypes.SET_STEP_YOUR_COVER);
    // }
}

export default Steppers;